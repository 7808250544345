<template>
  <div class="page">
    <img src="../../assets/images/newsBG.png" class="backgroundPic"/>
    <div class="flexBoxCol" style="padding: 30px 100px;box-sizing: border-box;">
      <div class="newsBox" v-if="currentId === '1'">
        <p class="span30-333">智慧校园的功能和意义何在？</p>
        <p class="p20">发表时间：2022-06-01 10:30</p>
        <p class="p24-666 text-indent">青少年、儿童是国家的未来，民族的希望，而校园安全问题事关亿万家庭幸福安宁和社会大局和谐稳定，加强国家安全防卫系统建设是增强校园安防能力、有效抵御不法侵害的迫切需求。市场监管总局（标准委）近日批准发布《中小学、幼儿园安全防范要求》
          国家标准于2022年6月1日起正式实施，这是2012年原《中小学、幼儿园安全技术防范系统要求》发布后首次修订。</p>
        <img src="../../assets/images/baichunyuanCase.png" style="width: 400px;height: 300px;margin: auto;"/>
        <p class="p24-666">
          主要修订内容如：<br>
          1.确定了校园安全防范系统建设的总体要求。
          特别强调了校园的安全防备应以保障学生和教职员工的人身安全为主要目的，明确了学校应与家长、上级主管单位和属地公安机关及所在乡镇（街道办事处）
          等建立联动联防和信息共享工作机制，共同构建内部、周边与社会相结合的校园安全防控体系。<br>
          2.完善了学校安全防控的16个重点部位和区域进行补充和完善。
          学校大门外；学校周界围墙；学校出入口；门卫室（传达室）；户外人员集中活动区；教学区域；学生宿舍区；幼儿园活动室、寝室；食堂；储存室、实验室...等公共区域。<br>
          3.完善了“人力防范”、“实体防范”、“电子防范”的具体要求。
          增加了保安员配备数量、巡逻、值守、学生上下学时段护卫等人力防卫要求，修改完善了重点部位和区域的实体防范和电子防范要求，提出了非常态防范期间，人力防范、实体防范和电子防范的相关要求。<br>
          4.更新了安全防范系统技术要求。
          针对校园安全防范系统的每个子系统，即入侵和紧急报警系统、视频监控系统、出入口控制系统、楼宇对讲系统、电子巡查系统、防爆安全检查系统以及实体防备设施等均提出了相应的技术要求，切实提升了中小学、
          幼儿园安全防备系统的防备能力与水平。 《要求》遵循“实用可行、科学先进、适度超前”的指导原则，以体现校园安全“人防、物防、技防相结合”为重点，保障学生和教职员工的人身安全为主要目标，从实际出发，
          强化问题导向，着力在重点部位、重要措施、应急处置、技术支撑等方面完善相关内容与技术规范，提升学校安全防范水平。核心内容包括“重点部位和区域”“总体防范要求”“人力防范要求”“实体防范要求”“电子防范要求”
          和“安全防范系统技术要求”等，适用于中小学和幼儿园安全防范系统的建设和管理，其他未成年人集中教育培训、救助保护等机构或场所安全防范系统的建设与管理可参照执行。本次的《要求》修订，是加强未成年人保护的重要举措，
          把校园安全防护区域从内部延伸到门口、周边地区；是适应当前校园安全形势的必然要求，着力解决难问题，坚持“软防护”和“硬防护”相结合，进一步完善“人防、物防、技防”就标准要求，推动校园安全防备工作更扎实有效，
          是“平安校园”建设的重要内容。
        </p>
      </div>
      <div class="newsBox" v-if="currentId === '2'">
        <p class="span30-333">斯点智慧校园</p>
        <p class="p20">发表时间：2022-06-01 09:30</p>
        <p class="p24-666 text-indent">湖南中联云达科技有限公司旗下品牌斯点智慧校园，提供智能数字化校园建设服务，通过电子学生证硬件和家校互动软件的物联融合，
          一站式有效解决中小学生双向通话（防沉迷）、实时定位（防走失）、安全围栏（防溺水）、SOS求教（防欺凌）、智能测温（防疫情）等等难题，让孩子日常更安心、
          让家长监管更放心、让老师教务更省心。</p>
        <img src="../../assets/images/baichunyuanCase.png" style="width: 400px;height: 300px;margin: auto;"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      casHeight: '500px',
      newsList: [],
      currentId: ''
    }
  },
  created () {
    this.currentId = this.$route.query.id
  },
  methods: {

  }
}
</script>
<style scoped lang="less">
  .newsBox {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
  .active {
    background: #F2F3F5;
  }
</style>
